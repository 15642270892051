
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        















































































@import '@/styles/_colors.scss';

.keyResult {
  display: grid;
  grid-gap: 0.25rem;
  grid-template-columns: 2rem 1fr span(1, 0, span(6));
  align-items: baseline;
  padding: 0.5rem 0.75rem;

  &.expanded {
    grid-template-columns: 2rem 1fr span(2, 0, span(6));
    padding: 1rem 0.75rem;
  }
}

.keyResult__icon {
  grid-column: 1;
}

.keyResult__name {
  grid-column: 2;
  color: $color-grey-800;
  text-decoration: none;

  @media screen and (min-width: bp(m)) {
    padding-right: 1rem;
  }
}

.keyResult__progression {
  grid-column: 3;
}

.keyResult__auto {
  grid-row: 1;
  grid-column: 1;
  width: auto;
  height: 100%;
  margin-right: 0.5rem;
  text-align: center;
  opacity: 0.5;
}

.keyResult__description {
  grid-row: 2;
  grid-column: 2;
  align-self: start;
  margin-top: 0.5rem;
  font-size: 0.8rem;

  @media screen and (min-width: bp(m)) {
    padding-right: 1rem;
  }
}

.keyResult__form {
  display: flex;
  grid-row: 3;
  grid-column: 2 / 4;
  margin-top: 1rem;
  margin-bottom: 1.5rem;

  @media screen and (min-width: bp(m)) {
    grid-row: 3;
    grid-column: 2;
  }

  @media screen and (min-width: bp(m)) {
    grid-row: 2;
    grid-column: 3;
  }
}

.keyResult__input {
  margin-right: 0.5rem;
}
